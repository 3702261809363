import * as React from 'react'
import { useTranslation } from 'react-i18next'
import img1 from '@assets/images/gallery/1.jpg'
import img2 from '@assets/images/gallery/2.jpg'
import img4 from '@assets/images/gallery/4.jpg'
import img5 from '@assets/images/gallery/5.jpg'
import img6 from '@assets/images/gallery/6.jpg'
import img7 from '@assets/images/gallery/7.jpg'
import img8 from '@assets/images/gallery/8.jpg'
import img11 from '@assets/images/gallery/11.jpg'
import { Button, useMediaQuery } from '@mui/material'
import { useTheme } from '@hooks/useTheme'
import { ActionButtonsContainer } from '@layout/ActionButtonsContainer'
import { FlexContainer } from '@layout/FlexContainer'
import styles from './Gallery.module.css'

const images = [
  { src: img1 },
  { src: img2 },
  { src: img4 },
  { src: img5 },
  { src: img6 },
  { src: img7 },
  { src: img8 },
  { src: img11 }
]

export const Gallery: React.FC = () => {
  const { t } = useTranslation()

  const { currentTheme } = useTheme()
  const isDownSm = useMediaQuery(currentTheme.breakpoints.down('sm'))

  const [showAll, setShowAll] = React.useState(false)

  const displayedImagesHandler = (): Array<{ src: string }> => {
    if (isDownSm) {
      return showAll ? images.slice(0, 12) : images.slice(0, 6)
    }
    return showAll ? images : images.slice(0, 8)
  }

  const displayedImages = displayedImagesHandler()

  const toggleShowAll = (): void => {
    setShowAll(!showAll)
  }

  return (
    <FlexContainer vertical>
      <h3>{t('pages.about.gallery')}</h3>
      <FlexContainer wrap justifyContentSpaceBetween noGap className={styles.imageContainer}>
        {displayedImages.map((image, i) => (
          <img alt="Gallery image" key={i} src={image.src} loading="lazy" />
        ))}
      </FlexContainer>
      {images.length > (isDownSm ? 6 : 8) && (
        <ActionButtonsContainer justifyContentCenterOnMd fullWidthButtonsOnSm>
          <Button variant="contained" onClick={toggleShowAll}>
            {!showAll ? t('pages.about.buttonShowMore') : t('pages.about.buttonHide')}
          </Button>
        </ActionButtonsContainer>
      )}
    </FlexContainer>
  )
}
